import React, { useState } from "react";
import styles from "./packageUpdate.module.scss";
import CustomUpdate from "../../components/packageUpdate/CustomUpdate";
import FlightUpdate from "../../components/packageUpdate/FlightUpdate";

function PackageUpdate() {
    const [type, setType] = useState("custom");
    return (
        <div className={styles.container}>
            <div className={styles.updateHeader}>
                <div className={styles.updateContainer} onClick={() => setType("custom")}>
                    <p className={styles.title} >
                        Custom Update
                    </p>
                </div>
                <div className={styles.updateContainer} onClick={() => setType("flight")}>
                    <p className={styles.title} >
                        Flight Update
                    </p>
                </div>
            </div>
            {type === "custom" && <CustomUpdate />}
            {type === "flight" && <FlightUpdate />}
        </div>
    );
}

export default PackageUpdate;
