import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./AppConfig";
// import Login from "./pages/login/Login";

import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingOverlay from "react-loading-overlay";

import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./App.scss";

// import Dashboard from "./components/Dashboard";
import Login from "./pages/auth/Login";
import { useHistory } from "react-router-dom";
import Signup from "./pages/signup/Signup";
import Customers from "./pages/customers/Customers";
import Customer from "./pages/customers/Customer";
import Packages from "./pages/packages/Packages";
import Package from "./pages/packages/Package";
import Labels from "./pages/labels/Labels";
import Label from "./pages/labels/Label";
// import Deliveries from "./pages/Delivery/Deliveries";
// import GenerateReport from "./pages/Delivery/GenerateReport";
// import DeliveryReport from "./pages/Delivery/DeliveryReport";
import BillingReports from "./pages/billing/BillingReports";
import GenerateInvoice from "./pages/billing/GenerateInvoice";
import Invoice from "./pages/billing/Invoice";
// import BarcodeScannerComponent from "react-qr-barcode-scanner";
import Scan from "./pages/scan/Scan";
// import firebase from "./firebase/firebase";
import Verify from "./components/Verify";
import ResetEmail from "./components/ResetEmail";
import ResetPassword from "./components/ResetPassword";
import MassLabel from "./pages/labels/MassLabel";
import Payment from "./pages/payment/Payment";
import PendingPackage from "./pages/pending packages/PendingPackage";
import "react-tippy/dist/tippy.css";
import Logs from "./pages/logs/Logs";
import Partner from "./pages/partners/Partner";
import BillingReport from "./pages/Invoice/BillingReports";
import Inv from "./pages/Invoice/Invoice";
import BulkInvoices from "./pages/billing/BulkInvoices";
import { MdOutlineCancel } from "react-icons/md";
import { IoMdExpand } from "react-icons/io";
import Dashboard from "./components/Dashboard";
import DeliveryInfos from "./pages/DeliveryInfo/DeliveryInfos";
import DeliveryInfo from "./pages/DeliveryInfo/DeliveryInfo";
import Statuses from "./pages/PackageStatus/Statuses";
import Status from "./pages/PackageStatus/Status";
import Advance from "./pages/Advance/Advance";
import InvoiceAdvance from "./pages/InvoiceAdvance/InvoiceAdvance";
import Report from "./pages/billing/Report";
import BulkInv from "./pages/Invoice/BulkInvoice";
import PaymentBulk from "./pages/payment/PaymentBulk";
import useUrlChange from "./components/useUrlChange";
import Outstanding from "./modals/Outstanding";
import { Dialog } from "primereact/dialog";
import { MdFlight } from "react-icons/md";
import Flights from "./pages/flight/Flights";
import Flight from "./pages/flight/Flight";
import DeliveryReport from "./pages/delivery/DeliveryReport";
import Vehicles from "./pages/delivery/vehicles/Vehicles";
import Milages from "./pages/delivery/milage/Milages";
import HandlerMilages from "./pages/delivery/handler/HandlerMilages";
import FinancialReport from "./pages/FinancialReport/FinancialReport";
import PackageUpdate from "./pages/packageUpdate/PackageUpdate";

const App = () => {
    const naviagte = useHistory();
    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const [role, setRole] = useState("");
    const copyTooltipRef = useRef();
    const location = useLocation();
    const [email, setEmail] = useState("");
    require("dotenv").config();
    PrimeReact.ripple = true;
    let key = localStorage.getItem("token");
    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (!token) {
            if (window.location.pathname === "/verify") {
                naviagte.push("/verify");
            } else if (window.location.pathname === "/reset") {
                naviagte.push("/reset");
            } else if (window.location.pathname === "/resetPassword") {
                naviagte.push("/resetPassword");
            } else {
                naviagte.push("/login");
            }
        }
    }, []);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    const isDesktop = () => {
        return window.innerWidth >= 992;
    };
    const menu = [
        {
            items: [{ label: "Dashboard", icon: "pi pi-chart-pie", to: "/" }],
        },
        {
            items: [
                {
                    label: "Users",
                    icon: "pi pi-user",
                    to: "/customers",
                },
            ],
        },
        {
            items: [
                {
                    label: "Partners",
                    icon: "pi pi-user-plus",
                    to: "/partners",
                },
            ],
        },
        {
            items: [
                {
                    label: "Packages",
                    icon: "pi pi-box",
                    to: "/packages",
                },
            ],
        },
        {
            items: [
                {
                    label: "Labels",
                    icon: "pi pi-tags",
                    to: "/labels",
                },
            ],
        },
        {
            items: [
                {
                    label: "Delivery report",
                    icon: "pi pi-file-o",
                    to: "/deliveryReport",
                },
            ],
        },
        {
            items: [
                {
                    label: "Invoices",
                    icon: "pi pi-wallet",
                    to: "/invoices",
                },
            ],
        },
        {
            items: [
                {
                    label: "Flights",
                    icon: "pi pi-send",
                    to: "/flights",
                },
            ],
        },
        {
            items: [
                {
                    label: "Scan",
                    icon: "pi pi-camera",
                    to: "/scan",
                },
            ],
        },
        {
            items: [
                {
                    label: "Advance Features",
                    icon: "pi pi-key",
                    to: "/advance",
                },
            ],
        },
       
    ];

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });

    useEffect(() => {
        const role = localStorage.getItem("role");
        const emial = localStorage.getItem("email");
        setRole(role);
        setEmail(emial);
    }, []);

    const courierMenu = [
        {
            items: [
                {
                    label: "Packages",
                    icon: "pi pi-circle",
                    to: "/",
                },
            ],
        },
        {
            items: [
                {
                    label: "Invoices",
                    icon: "pi pi-circle",
                    to: "/Userinvoices",
                },
            ],
        },
    ];

    const userMenu = [
        {
            items: [
                {
                    label: "Packages",
                    icon: "pi pi-circle",
                    to: "/",
                },
            ],
        },
        {
            items: [
                {
                    label: "Invoices",
                    icon: "pi pi-circle",
                    to: "/Userinvoices",
                },
            ],
        },
    ];

    const clerkMenu = [
        {
            items: [
                {
                    label: "Packages",
                    icon: "pi pi-circle",
                    to: "/packages",
                },
            ],
        },
        {
            items: [
                {
                    label: "Labels",
                    icon: "pi pi-circle",
                    to: "/labels",
                },
            ],
        },
        {
            items: [
                {
                    label: "Delivery report",
                    icon: "pi pi-circle",
                    to: "/deliveryReport",
                },
            ],
        },
        {
            items: [
                {
                    label: "Invoices",
                    icon: "pi pi-circle",
                    to: "/invoices",
                },
            ],
        },
        {
            items: [
                {
                    label: "Scan",
                    icon: "pi pi-circle",
                    to: "/scan",
                },
            ],
        },
    ];

    const airportMenu = [
        {
            items: [
                {
                    label: "Scan",
                    icon: "pi pi-circle",
                    to: "/scan",
                },
            ],
        },
    ];

    const handlerMenu = [
        {
            items: [
                {
                    label: "Delivery report",
                    icon: "pi pi-circle",
                    to: "/handlerDeliveryReport",
                },
            ],
        },
    ]

    const getMenu = () => {
        if (role === "admin") {
            return menu;
        } else if (role === "admin") {
            return menu;
        } else if (role === "courier") {
            return courierMenu;
        } else if (role === "client") {
            return userMenu;
        } else if (role === "clerk") {
            return clerkMenu;
        } else if (role === "airportStaff") {
            return airportMenu;
        }
        else if (role === "reseller") {
            return userMenu;
        }
        else if (role === "corporate") {
            return userMenu;
        }
        else if(role === "handler"){
            return handlerMenu;
        }
    };

    const getMainRoute = () => {
        if (role === "admin") {
            return <Route exact path="/" component={Dashboard} />;
        } else if (role === "courier") {
            return <Route exact path="/" component={Packages} />;
        } else if (role === "client") {
            return <Route exact path="/" component={Packages} />;
        } else if (role === "clerk") {
            return <Route exact path="/" component={Packages} />;
        } else if (role === "reseller") {
            return <Route exact path="/" component={Packages} />;
        } else if (role === "corporate") {
            return <Route exact path="/" component={Packages} />;
        }
        else if(role === "handler"){
            return <Route exact path="/" component={HandlerMilages} />;
        }
    };
    const [data, setData] = useState();

    const [collasped, setCollasped] = useState(false);
    console.log(collasped);

    const [showSoftStop, setShowSoftStop] = useState(false);

    const onHideSoftStop = () => {
        setShowSoftStop(false);
    }

    const onSuccessSoftStop = () => {
        setShowSoftStop(false);
    }


    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            
            <ToastContainer />
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />
            <>
                {window.location.pathname === "/login" ? (
                    <Route exact path="/login" component={Login} />
                ) : window.location.pathname === "/signup" ? (
                    <Route exact path="/signup" component={Signup} />
                ) : window.location.pathname === "/verify" ? (
                    <Route exact path="/verify" component={Verify} />
                ) : window.location.pathname === "/reset" ? (
                    <Route exact path="/reset" component={ResetEmail} />
                ) : window.location.pathname === "/resetPassword" ? (
                    <Route exact path="/resetPassword" component={ResetPassword} />
                ) : (
                    <>
                        <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />
                        <div className="layout-sidebar" onClick={onSidebarClick} style={{ width: `${collasped ? "80px" : "300px"}` }}>
                            <div style={{ display: "flex", justifyContent: "flex-end", height: "20px" }}>
                                {!collasped ? (
                                    <i onClick={() => setCollasped(true)} style={{ color: "red", fontSize: "20px", cursor: "pointer" }}>
                                        <MdOutlineCancel />
                                    </i>
                                ) : (
                                    <i onClick={() => setCollasped(false)} style={{ color: "green", fontSize: "20px", cursor: "pointer" }}>
                                        <IoMdExpand />
                                    </i>
                                )}
                            </div>
                            <AppMenu model={getMenu()} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} collasped={collasped} />
                        </div>
                        <div className={`${collasped ? "laymain2" : "layout-main-container"}`}>
                            <div className="layout-main">
                                {getMainRoute()}
                                {<Route exact path="/customers" component={Customers} />}
                                {<Route exact path="/customer/:id" component={Customer} />}
                                {<Route exact path="/packages" component={Packages} />}
                                {<Route exact path="/package/:id" component={Package} />}
                                {<Route exact path="/labels" component={Labels} />}
                                {<Route exact path="/label/:id" component={Label} />}
                                {<Route exact path="/invoices" component={InvoiceAdvance} />}
                                {<Route exact path="/generateInvoice" component={GenerateInvoice} />}
                                {<Route exact path="/invoice" component={Invoice} />}
                                {<Route exact path="/scan" component={Scan} />}
                                {<Route exact path="/BulkLabels" component={MassLabel} />}
                                {<Route exact path="/PendingPackages" component={PendingPackage} />}
                                {<Route exact path="/logs" component={Logs} />}
                                {<Route exact path="/payment" component={Payment} />}
                                {<Route exact path="/partners" component={Partner} />}
                                {<Route exact path="/Userinvoices" component={BillingReport} />}
                                {<Route exact path="/userInvoice" component={Inv} />}
                                {<Route exact path="/bulkInvoices" component={BulkInvoices} />}
                                {<Route exact path="/DeliveryInfos" component={DeliveryInfos} />}
                                {<Route exact path="/statuses" component={Statuses} />}
                                {<Route exact path="/DeliveryInfo/:id" component={DeliveryInfo} />}
                                {<Route exact path="/status/:id" component={Status} />}
                                {<Route exact path="/advance" component={Advance} />}
                                {<Route exact path="/invoice/:status" component={BillingReports} />}
                                {<Route exact path="/report/:status" component={Report} />}
                                {<Route exact path="/bulkInvoicesUser" component={BulkInv} />}
                                {<Route exact path="/bulkPayment" component={PaymentBulk} />}
                                {<Route exact path="/flights" component={Flights} />}
                                {<Route exact path="/flight" component={Flight} />}
                                {<Route exact path="/deliveryReport" component={DeliveryReport} />}
                                {<Route exact path="/vehicles" component={Vehicles} />}
                                {<Route exact path="/milageReports" component={Milages} />}
                                {<Route exact path="/handlerDeliveryReport" component={HandlerMilages} />}
                                {<Route exact path="/financialReport" component={FinancialReport} />}
                                {<Route exact path="/updatePackage" component={PackageUpdate} />}


                                {/* {<Route exact path="/deliveryReport/admin/generate" component={GenerateReport} />}
                                {<Route exact path="/delivery/:id" component={DeliveryReport} />} */}
                            </div>
                            {/* <AppFooter layoutColorMode={layoutColorMode} /> */}
                        </div>
                    </>
                )}
            </>

            <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>
        </div>
    );
};

export default App;
