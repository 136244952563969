import React, { useEffect, useState } from "react";
import styles from "./customUpdate.module.scss";
import Select from "react-select";
import { handleGetRequest } from "../../services/GetTemplate";
import { PackageTypes } from "../../assets/data/data";
import { toast } from "react-toastify";
import { handlePostRequest } from "../../services/PostTemplate";
import { useDispatch } from "react-redux";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

function CustomUpdate() {
    const [Packages, setPackages] = useState([]);
    const [originalPackages, setOriginalPackages] = useState([]);
    const [selectedPackages, setSelectedPackages] = useState([]);
    const [selectedCourier, setSelectedCourier] = useState();
    const [updates, setUpdates] = useState([]);
    const [waybills, setWaybills] = useState([]);
    const [users, setUsers] = useState([]);
    const [fields, setFields] = useState({
        PackageWeight: {
            state: false,
            value: "",
        },
        Unit: {
            state: false,
            value: "lb",
        },
        Description: {
            state: false,
            value: "",
        },
        BillTo: {
            state: false,
            value: "",
        },
        MerchantName: {
            state: false,
            value: "",
        },
        PackageName: {
            state: false,
            value: "",
        },
        TrackingNumber: {
            state: false,
            value: "",
        },
        ShipmentMethod: {
            state: false,
            value: "",
        },
        OversizedPackage: {
            state: false,
            value: "",
        },
        DeliveryInformation: {
            state: false,
            value: "",
        },
        ConsigneeName: {
            state: false,
            value: "",
        },
        PackageStatus: {
            state: false,
            value: "",
        },
        CourierName: {
            state: false,
            value: "",
        },
        PackageType: {
            state: false,
            value: "",
        },
        NumberOfPieces: {
            state: false,
            value: "",
        },
        Price: {
            state: false,
            value: "",
        },
        flightNUmber: {
            state: false,
            value: "",
        },
        notes: {
            state: false,
            value: "",
        },
    });
    const [selectedUser, setSelectedUser] = useState();
    const [deliveryInfos, setDeliveryInfos] = useState([]);
    const [packageStatuses, setPackageStatuses] = useState([]);
    const [couriers, setCouriers] = useState([]);

    const getPackages = async () => {
        console.log("called")
        const res = await handleGetRequest("/allPackages");
        setOriginalPackages(res?.data);
        const temp = res?.data?.map((item) => {
            return {
                value: item._id,
                label: item.Waybill + "--------" + item?.name?.firstName + " " + item?.name?.lastName,
            };
        });
        setPackages(temp);
        const res2 = await handleGetRequest("/allUsers");
        const temp2 = res2?.data?.map((item) => {
            return {
                value: item._id,
                label: item.firstName + " " + item.lastName,
            };
        });
        const result = await handleGetRequest("/userRole?role=courier");
        const temp3 = result?.data?.map((item) => {
            return {
                value: item._id,
                label: item?.courier?.businessName || item?.firstName + " " + item?.lastName,
            };
        });
        setCouriers(temp3);
        setUsers(temp2);
        const res3 = await handleGetRequest(`/getAllDeliveryInfo`);
        setDeliveryInfos(res3?.data);
        const resa = await handleGetRequest(`/getAllPackageStatus`);
        setPackageStatuses(resa?.data);
    };

    useEffect(() => {
        getPackages();
    }, []);

    const dispatch = useDispatch();

    const groupPackages = (selectedRow) => {
        const clients = selectedRow.filter((val) => {
            if (val?.consigneeType === "client" || val?.consigneeType === "reseller" || val?.consigneeType === "corporate" || val?.consigneeType === "admin" || val?.consigneeType === "partner") {
                return val;
            }
        });
        const couriers = selectedRow.filter((val) => {
            if (val?.consigneeType === "courier") {
                return val;
            }
        });
        const groupedPackages = clients.reduce((groups, pkg) => {
            const { ...packageData } = pkg;

            const existingGroup = groups.find((group) => group?.name === pkg?.name?.firstName + " " + pkg?.name?.lastName);
            if (existingGroup) {
                existingGroup.description.push(packageData?.description);
            } else {
                groups.push({
                    id: pkg?.Waybill,
                    name: pkg?.name?.firstName + " " + pkg?.name?.lastName,
                    type: "client",
                    description: [packageData?.description],
                    email: pkg?.name?.email,
                    deliveryInfo: pkg?.deliveryInfo,
                    tracking: pkg?.trackingNumber,
                    shipmentMethod: pkg?.shipmentMethod,
                });
            }

            return groups;
        }, []);
        const cos = couriers.reduce((groups, pkg) => {
            const { ...packageData } = pkg;

            const existingGroup = groups.find((group) => group?.name === pkg?.courierName?.courier?.businessName);
            if (existingGroup) {
                existingGroup.description.push(packageData?.Waybill);
            } else {
                groups.push({
                    id: pkg?.Waybill,
                    name: pkg?.courierName?.courier?.businessName,
                    type: "courier",
                    description: [packageData?.Waybill],
                    email: pkg?.courierName?.email,
                    deliveryInfo: pkg?.deliveryInfo,
                });
            }

            return groups;
        }, []);
        const final = groupedPackages.concat(cos);
        return final;
    };
    // console.log(originalPackages);

    const handleUpdate = async () => {
        if (selectedPackages?.length > 0) {
            const temp = originalPackages.filter((item) => selectedPackages.map((item) => item.value).includes(item._id));
            const Waybills = temp.map((item) => item.Waybill);
            const final = groupPackages(temp);
            const data = {
                ids: selectedPackages.map((item) => item.value),
                ...(fields?.PackageWeight.state && { PackageWeight: fields?.PackageWeight.value }),
                ...(fields?.PackageWeight.state && { unit: fields?.Unit.value }),
                ...(fields?.Description.state && { description: fields?.Description.value }),
                ...(fields?.BillTo.state && { name: selectedUser?.value }),
                ...(fields?.MerchantName.state && { merchantName: fields?.MerchantName.value }),
                ...(fields?.PackageName.state && { billTo: fields?.PackageName.value }),
                ...(fields?.TrackingNumber.state && { trackingNumber: fields?.TrackingNumber.value }),
                ...(fields?.ShipmentMethod.state && { shipmentMethod: fields?.ShipmentMethod.value }),
                ...(fields?.OversizedPackage.state && { oversizedPackage: fields?.OversizedPackage.value }),
                ...(fields?.DeliveryInformation.state && { deliveryInformation: fields?.DeliveryInformation.value }),
                ...(fields?.ConsigneeName.state && { consigneeName: fields?.ConsigneeName.value }),
                ...(fields?.PackageStatus.state && { packageStatus: fields?.PackageStatus.value }),
                ...(fields?.CourierName.state && { courierName: selectedCourier?.value }),
                ...(fields?.PackageType.state && { packageType: fields?.PackageType.value }),
                ...(fields?.NumberOfPieces.state && { numberOfPieces: fields?.NumberOfPieces.value }),
                ...(fields?.Price.state && { price: fields?.Price.value }),
                ...(fields?.flightNUmber.state && { flightNumber: fields?.flightNUmber.value }),
                ...(fields?.notes.state && { notes: fields?.notes.value }),
                ...(fields?.PackageStatus.state && { Waybills: Waybills }),
                ...(fields?.PackageStatus.state && { package: final }),
            };
            const res = await dispatch(handlePostRequest(data, "/massUpdatePackages"));
            if (res?.success) {
                toast.success("Packages updated successfully");
            }

            console.log(data);
        } else {
            toast.error("Please select a package to update");
        }
    };

    const [type, setType] = useState("manual");
    const [data, setData] = useState("Not Found");


    const handleScan=()=>{
        const temp = originalPackages.filter((item) => item.Waybill === data);
        if (temp.length > 0 && !selectedPackages.some((pkg) => pkg.value === temp[0]._id)) {
            setSelectedPackages([...selectedPackages, { value: temp[0]._id, label: temp[0].Waybill + "--------" + temp[0]?.name?.firstName + " " + temp[0]?.name?.lastName }]);
        }
    }

    useEffect(() => {
            if (data !== "Not Found") {
                handleScan();
            }
        }, [data]);

    return (
        <div className={styles.container}>
            <div className={styles.selectionContainer}>
                {Object.keys(fields).map((fieldKey) => (
                    <div
                        key={fieldKey}
                        className={styles.selectionDiv}
                        onClick={() => {
                            setFields({
                                ...fields,
                                [fieldKey]: {
                                    ...fields[fieldKey],
                                    state: !fields[fieldKey].state,
                                },
                            });
                        }}
                    >
                        <div className={styles.upperDiv}>
                            <input
                                type="checkbox"
                                checked={fields[fieldKey].state}
                                readOnly
                            />
                            <label>{fieldKey}</label>
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.innerSelection}>
                {/* <button onClick={()=>console.log(originalPackages)}>Show ME</button> */}
                <button className={styles.updateButton} onClick={()=>setType("manual")}>Manual Update</button>
                <button className={styles.Scan} onClick={()=>setType("scan")}>Scan</button>
            </div>
            {type === "manual" ? (
                <div className={styles.manual}>
                    <Select options={Packages} value={selectedPackages} onChange={setSelectedPackages} isMulti></Select>
                </div>
            ) : (
                <div className={styles.cameraDiv}>
                    <BarcodeScannerComponent
                        width={300}
                        height={220}
                        onUpdate={(err, result) => {
                            if (result) setData(result.text);
                            else setData("Not Found");
                        }}
                    />
                </div>
            )}
            <div className={styles.selectedPackages}>
                {selectedPackages?.length > 0 && (
                    <div className={styles.innerOne}>
                        {selectedPackages.map((item) => {
                            return <div>{item.label}</div>;
                        })}
                    </div>
                )}
            </div>
            <div className={styles.modificationDiv}>
                <p>Fields To Update</p>
            </div>
            <div className={styles.mostInnerContainer}>
                {fields?.PackageWeight.state && (
                    <div className={styles.mostInner}>
                        <label>Package Weight</label>
                        <input type="text" placeholder="Package Weight" value={fields.PackageWeight.value} onChange={(e) => setFields({ ...fields, PackageWeight: { ...fields?.PackageWeight, value: e.target.value } })}></input>
                    </div>
                )}
                {fields?.Unit.state && (
                    <div className={styles.mostInner}>
                        <label>Weight Unit</label>
                        <select value={fields.Unit.value} onChange={(e) => setFields({ ...fields, Unit: { ...fields?.Unit, value: e.target.value } })}>
                            <option disabled selected>
                                Please select a unit
                            </option>
                            <option value="lb">Lb</option>
                            <option value="cu">Cu ft</option>
                            <option value="kg">Kg</option>
                        </select>
                    </div>
                )}
                {fields?.Description.state && (
                    <div className={styles.mostInner}>
                        <label>Package Description</label>
                        <input type="text" placeholder="Package Description" value={fields.Description.value} onChange={(e) => setFields({ ...fields, Description: { ...fields?.Description, value: e.target.value } })}></input>
                    </div>
                )}
                {fields?.BillTo.state && (
                    <div className={styles.mostInner}>
                        <label>billTo</label>
                        <Select options={users} value={selectedUser} onChange={setSelectedUser}></Select>
                    </div>
                )}
                {fields?.MerchantName.state && (
                    <div className={styles.mostInner}>
                        <label>Merchant Name</label>
                        <input type="text" placeholder="Package Description" value={fields.MerchantName.value} onChange={(e) => setFields({ ...fields, MerchantName: { ...fields?.MerchantName, value: e.target.value } })}></input>
                    </div>
                )}
                {fields?.PackageName.state && (
                    <div className={styles.mostInner}>
                        <label>Package Name</label>
                        <input type="text" placeholder="Package Description" value={fields.PackageName.value} onChange={(e) => setFields({ ...fields, PackageName: { ...fields?.PackageName, value: e.target.value } })}></input>
                    </div>
                )}
                {fields?.TrackingNumber.state && (
                    <div className={styles.mostInner}>
                        <label>Tracking Number</label>
                        <input type="text" placeholder="Package Description" value={fields.TrackingNumber.value} onChange={(e) => setFields({ ...fields, TrackingNumber: { ...fields?.TrackingNumber, value: e.target.value } })}></input>
                    </div>
                )}
                {fields?.ShipmentMethod.state && (
                    <div className={styles.mostInner}>
                        <label>Shipment Method</label>
                        <select value={fields.ShipmentMethod.value} onChange={(e) => setFields({ ...fields, ShipmentMethod: { ...fields?.ShipmentMethod, value: e.target.value } })}>
                            <option selected disabled>
                                Please select a shipment method
                            </option>
                            <option value="shipment">shipment</option>
                            <option value="air">Air</option>
                        </select>
                    </div>
                )}
                {fields?.OversizedPackage.state && (
                    <div className={styles.mostInner}>
                        <label>Oversized Package</label>
                        <select value={fields.OversizedPackage.value} onChange={(e) => setFields({ ...fields, OversizedPackage: { ...fields?.OversizedPackage, value: e.target.value } })}>
                            <option disabled selected>
                                Please select a package condition
                            </option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </select>
                    </div>
                )}
                {fields?.DeliveryInformation.state && (
                    <div className={styles.mostInner}>
                        <label>Delivery Information</label>
                        <select value={fields.DeliveryInformation.value} onChange={(e) => setFields({ ...fields, DeliveryInformation: { ...fields?.DeliveryInformation, value: e.target.value } })}>
                            <option disabled selected>
                                Please select delivery Information
                            </option>
                            {deliveryInfos?.map((client) => {
                                return <option value={client?.name}>{client?.name}</option>;
                            })}
                        </select>
                    </div>
                )}
                {fields?.ConsigneeName.state && (
                    <div className={styles.mostInner}>
                        <label>Consignee Name</label>
                        <input type="text" placeholder="Consignee Name" value={fields.ConsigneeName.value} onChange={(e) => setFields({ ...fields, ConsigneeName: { ...fields?.ConsigneeName, value: e.target.value } })}></input>
                    </div>
                )}
                {fields?.PackageStatus.state && (
                    <div className={styles.mostInner}>
                        <label>Package Status</label>
                        <select value={fields.PackageStatus.value} onChange={(e) => setFields({ ...fields, PackageStatus: { ...fields?.PackageStatus, value: e.target.value } })}>
                            <option disabled selected>
                                Please select a package status
                            </option>
                            {packageStatuses?.map((client) => {
                                return <option value={client?.name}>{client?.name}</option>;
                            })}
                        </select>
                    </div>
                )}
                {fields?.CourierName.state && (
                    <div className={styles.mostInner}>
                        <label>Courier Name</label>
                        <Select options={couriers} value={selectedCourier} onChange={setSelectedCourier}></Select>
                    </div>
                )}
                {fields?.PackageType.state && (
                    <div className={styles.mostInner}>
                        <label>Package Status</label>
                        <select value={fields.PackageType.value} onChange={(e) => setFields({ ...fields, PackageType: { ...fields?.PackageType, value: e.target.value } })}>
                            <option disabled selected>
                                Please select a package Type
                            </option>
                            {PackageTypes?.map((client) => {
                                return <option value={client}>{client}</option>;
                            })}
                        </select>
                    </div>
                )}
                {fields?.NumberOfPieces.state && (
                    <div className={styles.mostInner}>
                        <label>Number of pieces</label>
                        <input type="text" placeholder="Number of pieces" value={fields.NumberOfPieces.value} onChange={(e) => setFields({ ...fields, NumberOfPieces: { ...fields?.NumberOfPieces, value: e.target.value } })}></input>
                    </div>
                )}
                {fields?.Price.state && (
                    <div className={styles.mostInner}>
                        <label>Price</label>
                        <input type="text" placeholder="Price" value={fields.Price.value} onChange={(e) => setFields({ ...fields, Price: { ...fields?.Price, value: e.target.value } })}></input>
                    </div>
                )}
                {fields?.flightNUmber.state && (
                    <div className={styles.mostInner}>
                        <label>Flight Number</label>
                        <input type="text" placeholder="Flight Number" value={fields.flightNUmber.value} onChange={(e) => setFields({ ...fields, flightNUmber: { ...fields?.flightNUmber, value: e.target.value } })}></input>
                    </div>
                )}
                {fields?.notes.state && (
                    <div className={styles.mostInner}>
                        <label>notes</label>
                        <input type="text" placeholder="notes" value={fields.notes.value} onChange={(e) => setFields({ ...fields, notes: { ...fields?.notes, value: e.target.value } })}></input>
                    </div>
                )}
            </div>
            <div className={styles.updateButtonContainer}>
                <button className={styles.updateButton} onClick={handleUpdate}>
                    Update
                </button>
            </div>
        </div>
    );
}

export default CustomUpdate;
